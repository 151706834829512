.Project {
  background-color: var(--white);
  border-radius: var(--radius);
  width: 100%;
  margin: 0.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: var(--shadow);
}
.Project a {
  color: inherit;
  text-decoration: none;
}
.image img {
  width: 100%;
  height: auto;
}
.Project h2 {
  text-align: center;
  margin: 1rem;
}
.Project p {
  padding: 0 1rem;
  margin-top: 0;
}
.tech {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.tech::before,
.tech::after {
  content: '';
  height: 1px;
  background-image: linear-gradient(
    to right,
    var(--white),
    var(--primary),
    var(--primary),
    var(--primary),
    var(--white)
  );
  margin: 0 1rem;
  opacity: 0.8;
}
.Project ul {
  margin: 0 1rem;
  padding: 0.5rem 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  color: var(--primary);
}
.buttons {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}
.buttons a {
  background-color: inherit;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  font-size: inherit;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  opacity: 0.8;
  border-radius: var(--radius);
  transition: all 0.2s;
}
.buttons a:hover {
  box-shadow: 0 0 3px var(--secondary);
  opacity: 1;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

@media screen and (min-width: 567px) {
  .Project {
    max-width: 300px;
    margin-bottom: 0.5rem;
  }
}
@media screen and (min-width: 762px) {
  .Project {
    max-width: 300px;
  }
  .Project:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-hover);
  }
}
