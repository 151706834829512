.ProjectTechIcon {
  margin: 0 0.5rem;
  font-size: 2rem;
}

@media screen and (min-width: 576px) {
  .ProjectTechIcon {
    font-size: 2rem;
  }
}
