.Projects {
  margin-top: 14rem;
  position: relative;
  background-color: var(--secondary);
  background-image: linear-gradient(
    to bottom,
    var(--secondary),
    var(--secondary-hilight),
    var(--secondary-hilight)
  );
}
.Projects::before {
  --size: 600vw;
  content: '';
  position: absolute;
  top: calc(var(--size) * -1);
  left: calc((var(--size) - 100vw) / -2);
  width: var(--size);
  height: var(--size);
  z-index: -1;
  background-color: var(--primary);
  border-radius: 50%;
}
.Projects h1,
.projectCards {
  transform: translateY(-10rem);
}
.projectCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0.5rem;
  max-width: 1400px;
  margin: 0 auto;
}
