.fa-java,
.fa-php,
.fa-react {
  font-weight: bold;
}
.up-arrow-container {
  display: flex;
  justify-content: center;
  padding: 1.2rem 0 2rem 0;
  background-color: var(--secondary-hilight);
}
