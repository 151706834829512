.Intro {
  --max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: var(--max-width);
  overflow: hidden;
  border-radius: var(--radius);
  margin: 0 1rem;
  margin-bottom: 1rem;
  box-shadow: var(--shadow);
}
.topRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  --size: 150px;
  height: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  padding: 0.2rem;
  border: 3px solid var(--secondary);
  border-radius: 50%;
  transition: all 0.2s;
  box-shadow: 0 0 0 var(--max-width) var(--white); /* Background */
  margin: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  width: 100%;
}
.text {
  margin: 0 2rem;
}
.text h2 {
  margin: 0 auto 0.2rem auto;
  color: var(--secondary);
}
.text p {
  margin: 0.5rem auto;
}
.text a {
  color: var(--secondary);
  text-decoration: none;
  transition: var(--transition);
}
.text a:hover {
  color: var(--primary);
}

@media screen and (min-width: 576px) {
  .text h2 {
    margin-top: -2rem;
  }
}

@media screen and (min-width: 768px) {
  .Intro {
    margin-bottom: 2rem;
  }
  .topRow {
    flex-direction: row;
    margin-bottom: -2rem;
    margin-top: 1rem;
  }
  .avatar {
    --size: 200px;
    margin-bottom: 2rem;
  }
  .text {
    margin-left: 0;
    margin-bottom: 2rem;
  }
  .text h2 {
    margin-top: 0;
    margin-left: -1rem;
  }
}
