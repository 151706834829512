.Arrow {
  color: var(--white);
  opacity: 0.8;
  font-size: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  border: 3px solid transparent;
  border-radius: 50%;
  text-decoration: none;
}
.Arrow.down {
  animation: bob-down 1.5s infinite;
}
.Arrow.up {
  animation: bob-up 1.5s infinite;
}
.Arrow.down i {
  transform: translateY(0.18rem);
}
.Arrow.up i {
  transform: translateY(-0.18rem);
}
@media screen and (min-width: 768px) {
  .Arrow:hover {
    border-color: var(--white);
    opacity: 1;
  }
}

@keyframes bob-down {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(5px);
  }
  66% {
    transform: translateY(0);
  }
}
@keyframes bob-up {
  0% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(-5px);
  }
  66% {
    transform: translateY(0);
  }
}
