.IntroLink {
  margin: 0.5rem 0.7rem;
  position: relative;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32px;
  font-size: 2rem;
  color: var(--primary);
  transform: translateY(-0.375em);
}
.IntroLink a {
  color: inherit;
  text-decoration: none;
  transition: var(--transition);
}
.IntroLink:hover,
.IntroLink label:hover {
  color: var(--secondary);
}
.IntroLink label {
  font-size: 0.45em;
  text-align: center;
  margin-bottom: -1em;
  transition: var(--transition);
  color: inherit;
}

@media screen and (min-width: 768px) {
  .IntroLink {
    transform: none;
    margin: 0 0.5rem;
    font-size: 2rem;
  }
  .IntroLink:hover {
    transform: translateY(-0.25em);
  }
  .IntroLink label {
    opacity: 0;
    transform: translateY(-2em);
  }
  .IntroLink:hover label {
    opacity: 1;
    transform: translateY(-0.125em);
    color: var(--secondary);
  }
}
