@font-face {
  font-family: 'Louis George Cafe';
  src: local('Louis George Cafe'),
    url(./assets/fonts/LouisGeorgeCafe.ttf) format('truetype');
}
:root {
  --hilight: #7fffd4;
  --primary: #40e0d0;
  --secondary: #00c8e4;
  --secondary-hilight: #00e1ff;
  --dark: #545479;
  --white: #eef8f8;
  --radius: 4px;
  --transition: all 0.2s;
  --shadow: 0 8px 16px #54547933, 0 6px 6px #54547911;
  --shadow-hover: 0 10px 20px #54547944, 0 8px 8px #54547922;
}
::selection {
  color: var(--white);
  background-color: var(--primary);
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  scroll-behavior: smooth;
}
body,
#root {
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: 'Louis George Cafe', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  color: var(--dark);
  background-color: var(--secondary);
}
h1 {
  text-align: center;
  color: var(--white);
  font-size: 2rem;
  transition: var(--transition);
}
h2 {
  font-size: 1.5rem;
  color: var(--secondary);
}
p {
  line-height: 1.5;
}

@media screen and (min-width: 576px) {
  h3 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
}
