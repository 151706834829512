.ExperienceIcon {
  margin: 0.5rem 0.7rem;
  color: var(--primary);
  font-size: 2rem;
  transition: all 0.2s;
  transform: translateY(-0.25em);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
}
.ExperienceIcon label {
  font-size: 0.4em;
  transform: translateY(0.25em);
  transition: var(--transition);
  height: 0.75em;
}

@media screen and (min-width: 576px) {
  .ExperienceIcon {
    font-size: 2.5rem;
    margin: 0.625rem 1rem;
  }
}
@media screen and (min-width: 768px) {
  .ExperienceIcon {
    font-size: 3rem;
    margin-bottom: 0;
    transform: none;
  }
  .ExperienceIcon:hover {
    transform: translateY(-0.25em);
  }
  .ExperienceIcon label {
    transform: translateY(-2em);
    opacity: 0;
  }
  .ExperienceIcon:hover label {
    transform: translateY(-0.125em);
    opacity: 1;
  }
}
