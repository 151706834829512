.Experience {
  background-color: var(--primary);
  position: relative;
  padding: 1.5rem 1rem 0 1rem;
  margin-bottom: 18rem;
}
.Experience h1 {
  line-height: 1.5rem;
  margin-top: 0;
}
.Experience h1 span {
  font-size: 1.5rem;
}
.box {
  margin: 0 auto;
  background-color: var(--white);
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
}

@media screen and (min-width: 576px) {
  .box {
    max-width: 616px;
  }
}
