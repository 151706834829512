.IntroLinks {
  display: flex;
  list-style-type: none;
  margin: 0.75rem 0;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .IntroLinks {
    margin-top: 0;
  }
}
