.ExperienceList {
  margin: 1rem;
  transition: all 0.2s;
  position: relative;
}
.ExperienceList:not(:last-child)::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  content: '';
  height: 1px;
  background-color: red;
  background-image: linear-gradient(
    to right,
    var(--white),
    var(--primary),
    var(--primary),
    var(--primary),
    var(--white)
  );
}
.ExperienceList:last-child {
  border-bottom: none;
  margin-bottom: 0;
  margin-top: 0;
}
.ExperienceList h2 {
  margin: 0;
  margin-bottom: 0.5em;
  padding-bottom: 0;
  transition: all 0.2s;
}
.ExperienceList ul {
  margin: 0.5rem;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (min-width: 576px) {
  .ExperienceList {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
