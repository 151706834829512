.Footer {
  z-index: 2;
  background-color: var(--dark);
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--transition);
}
.text {
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.8;
  font-size: 0.9rem;
}
.text p {
  margin: 0.5rem 1rem;
}
.text a {
  color: inherit;
}
.Footer img {
  height: 80px;
  width: 80px;
  border: 3px solid var(--primary);
  background-color: var(--secondary-hilight);
  border-radius: 50%;
  margin: 1rem;
  transition: var(--transition);
}

@media screen and (min-width: 576px) {
  .text p {
    margin-left: 1.5rem;
  }
  .Footer img {
    height: 100px;
    width: 100px;
    margin: 1.5rem;
  }
}
